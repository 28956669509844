/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { useStaticQuery, graphql, Link } from 'gatsby'
import SmileSVG from '../images/vectors/smile.inline.svg'

const CompanyContainer = styled.div`
  width: 100%;
  flex: auto;
  font-size: ${props => props.theme.fontSizes[5]}px;

  @media (max-width: 1400px) {
    font-size: ${props => props.theme.fontSizes[4]}px;
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[3]}px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    font-size: ${props => props.theme.fontSizes[3]}px;
  }
`

const DetailLink = styled.a`
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin-bottom: 20px;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 50%;
    &:nth-of-type(2),
    &:nth-of-type(4) {
      padding-left: 20px;
    }
  }

  .call {
    display: inline-block;
    text-align: center;
    border: 2px solid ${props => props.theme.colors.red};
    padding: 6px 20px;
    width: 110px;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      width: 90px;
      padding: 3px 10px;
    }
  }

  .fax {
    display: inline-block;
    text-align: center;
    width: 110px;
    padding: 6px 20px;
    position: relative;
    z-index: 4;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      width: 90px;
      padding: 3px 10px;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 0 50%);
      background-color: ${props => props.theme.colors.background};
      z-index: -1;
    }
    &::after {
      content: '';
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      position: absolute;
      left: -2px;
      top: -2px;
      clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 0 50%);
      background-color: ${props => props.theme.colors.red};
      z-index: -2;
    }
  }
`

const DetailLinkG = styled(Link)`
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 50%;
    &:nth-of-type(2),
    &:nth-of-type(3) {
      padding-left: 20px;
    }
  }
  margin-bottom: 20px;
`

const DetailContainer = styled.div`
  display: inline-block;
  padding-right: 20px;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    padding-right: 10px;
  }
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    width: 110px;
    text-align: right;
    padding-right: 20px;
  }
`

const Smiley = styled(SmileSVG)`
  max-width: 100%;
  width: 40px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 6px;
  line-height: 1em;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    width: 33px;
    margin-bottom: 4px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    width: 23px;
    margin-bottom: 4px;
  }
`

const SmileyContainer = styled.div`
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border: 2px solid #ed6941;
  border-radius: 999px;
  text-align: center;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
`

const Asterix = styled.div`
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 106px;
  border: 2px solid #ed6941;
  border-radius: 999px;
  text-align: center;
  font-size: 90px;
  vertical-align: middle;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    width: 50px;
    height: 50px;
    line-height: 73px;
    font-size: 70px;
  }
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    width: 40px;
    height: 40px;
    line-height: 63px;
    font-size: 60px;
  }
`
const Adresse = styled.div`
  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 50%;
  }
  white-space: pre;
  display: block;
  float: left;
  margin-top: 20px;
`
const Gesellschafter = styled.div`
  white-space: pre;
  width: 100%;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 50%;
    padding-left: 20px;
  }
  display: block;
  float: left;
  margin-top: 20px;
`

const ContactDetails = props => {
  const contactQuery = useStaticQuery(graphql`
    {
      contentfulCompany(unternehmensnamekurz: { eq: "EXTENDIT" }) {
        fax
        telefonnummer
        unternehmensname
        email
        adresse {
          internal {
            content
          }
        }
        gesellschafter {
          internal {
            content
          }
        }
      }
    }
  `)
  const telefonnummer = `tel:${contactQuery.contentfulCompany.telefonnummer}`
  const mail = `mailto:${contactQuery.contentfulCompany.email}`

  return (
    <CompanyContainer>
      <DetailLink href={telefonnummer} onClick={() => typeof window !== "undefined" && window.gtag("event", "clickTelefon", {send_to: "AW-983019446/8KoeCJfTiskCELbf3tQD"})}>
        <DetailContainer>
          <span className="call">call</span>
        </DetailContainer>
        {contactQuery.contentfulCompany.telefonnummer}
      </DetailLink>
      <DetailLink href={'https://helpdesk.extendit.at/hc/de'} target="_blank">
        <DetailContainer>
          <Asterix>*</Asterix>
        </DetailContainer>
        Help Desk
      </DetailLink>
      <DetailLink href={mail} onClick={() => typeof window !== "undefined" && window.gtag("event", "clickEmail", {send_to: "AW-983019446/eTYmCInXiskCELbf3tQD"})}>
        <DetailContainer>
          <span className="fax">email</span>
        </DetailContainer>
        {contactQuery.contentfulCompany.email}
      </DetailLink>
      <DetailLink>
        <DetailContainer>
        
        </DetailContainer>
        
      </DetailLink>
      <Adresse>
        {contactQuery.contentfulCompany.unternehmensname}
        <br />
        {contactQuery.contentfulCompany.adresse.internal.content}
      </Adresse>
      <Gesellschafter>
        {contactQuery.contentfulCompany.gesellschafter.internal.content}
      </Gesellschafter>
    </CompanyContainer>
  )
}

export default ContactDetails
