import React from 'react'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import Container from '../components/Container'
import ContainerHero from '../components/ContainerHero'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'
import ContactDetails from '../components/ContactDetails'
import Newsletter from '../components/NewsletterPopup.js'

const TitleLeft = styled.div`
  font-size: ${props => props.theme.fontSizes[5]}px;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    font-size: ${props => props.theme.fontSizes[6]}px;
  }
  @media (min-width: ${props => props.theme.responsive.large}) {
    font-size: ${props => props.theme.fontSizes[7]}px;
  }
`

const HeroKontakt = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-width: 100%;
  @media (min-width: ${props => props.theme.responsive.large}) {
    flex-flow: row nowrap;
  }
`

const HeroSmallLeft = styled.div`
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 30px 15px 0px;
  font-size: ${props => props.theme.fontSizes[7]}px;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    flex: auto;
    padding: 40px 0px 30px 60px;
  }
`

const HeroCenter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-width: 100%;
  padding: 30px 15px;
  font-size: ${props => props.theme.fontSizes[5]}px;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 60px;
  }
`

const Contact = ({ data }) => {
  return (
    <Layout>
      <Newsletter />
      <SEO
        title="Kontakt"
        description="Wir helfen gerne weiter! Kontaktieren Sie uns!"
      />
      <Container>
        <ContainerHero newsletter>
          <HeroKontakt>
            <HeroSmallLeft>
              <TitleLeft>Hello</TitleLeft>
            </HeroSmallLeft>
            <HeroCenter>
              <ContactForm />
              <ContactDetails />
            </HeroCenter>
          </HeroKontakt>
        </ContainerHero>
      </Container>
    </Layout>
  )
}

export default Contact
