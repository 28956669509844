import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

/*
  This is an example of a contact form powered with Netlify form handling.
  Be sure to review the Netlify documentation for more information:
  https://www.netlify.com/docs/form-handling/
*/

const Form = styled.form`
  z-index: 5;
  margin-bottom: 60px;
  max-width: 100%;
  width: 100%;
  flex: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  .formInput,
  textarea {
    font-family: inherit;
    font-size: ${props => props.theme.fontSizes[3]}px;
    background: ${props => props.theme.colors.background};
    border: 2px solid ${props => props.theme.colors.text};
    color: ${props => props.theme.colors.text};
    border-radius: 0px;
    padding: 1em;
    &::-webkit-input-placeholder {
      color: gray;
    }
    &::-moz-placeholder {
      color: gray;
    }
    &:-ms-input-placeholder {
      color: gray;
    }
    &:-moz-placeholder {
      color: gray;
    }
    &:required {
      box-shadow: none;
    }
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      font-size: ${props => props.theme.fontSizes[1]}px;
      padding: 10px;
      margin-bottom: 5px;
    }
  }
  &::before {
    content: '';
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${props => (props.overlay ? '.8' : '0')};
    visibility: ${props => (props.overlay ? 'visible' : 'hidden')};
  }
`

const FormDetails = styled.div`
  display: block;
  width: 100%;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 49%;
  }
`

const Name = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
`

const Email = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
`

const Phone = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
`

const Message = styled.textarea`
  width: 100%;
  min-height: 170px;
  margin: 0 0 1em 0;
  line-height: 1.6;
  resize: vertical;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 49%;
    min-height: 370px;
  }
`

const Submit = styled.input`
  background: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  display: block;
  border: 2px solid ${props => props.theme.colors.red};
  border-radius: 999px;
  font-size: 1em;
  outline: none;
  cursor: pointer;
  padding: 20px 40px;
  text-decoration: none;
  transition: 0.2s;
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${props => props.theme.colors.text};
    background: ${props => props.theme.colors.red};
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    padding: 15px 30px;
    margin-top: 15px;
    font-size: ${props => props.theme.fontSizes[4]}px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    margin-top: 15px;
    padding: 10px 15px;
    font-size: ${props => props.theme.fontSizes[3]}px;
  }
`

const Modal = styled.div`
  background: white;
  padding: 2em;
  border-radius: 0px;
  position: fixed;
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    min-width: inherit;
    max-width: 400px;
  }
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }
`

const Button = styled.div`
  border: 2px solid ${props => props.theme.colors.text};
  background: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  display: block;
  outline: none;
  cursor: pointer;
  padding: 0.5em 2em;
  border-radius: 999px;
  text-decoration: none;
  transition: 0.2s;
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${props => props.theme.colors.background};
    background: ${props => props.theme.colors.text};
  }
`

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      showModal: false,
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    event.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      name: '',
      email: '',
      message: '',
      showModal: true,
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <Form
        name="contact"
        onSubmit={this.handleSubmit}
        data-netlify="true"
        data-netlify-honeypot="bot"
        overlay={this.state.showModal}
        onClick={this.closeModal}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot" onChange={this.handleInputChange} />
          </label>
        </p>
        <Message
          name="message"
          type="text"
          placeholder="Nachricht"
          value={this.state.message}
          onChange={this.handleInputChange}
          required
        />
        <FormDetails>
          <Name
            name="name"
            type="text"
            placeholder="Name"
            className="formInput"
            value={this.state.name}
            onChange={this.handleInputChange}
            required
          />
          <Email
            name="email"
            type="email"
            placeholder="E-Mail"
            className="formInput"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
          <Phone
            name="phone"
            type="text"
            placeholder="Telefon"
            className="formInput"
            value={this.state.phone}
            onChange={this.handleInputChange}
          />
        </FormDetails>
        <Submit name="submit" type="submit" value="Absenden" onClick={() => typeof window !== "undefined" && window.gtag("event", "clickKontaktform", {send_to: "AW-983019446/gVl7CP3byc0CELbf3tQD"})} />
        <Modal visible={this.state.showModal}>
          <p>Danke für Ihre Anfrage. Wir melden uns in Kürze.</p>
          <Button onClick={this.closeModal}>Schließen</Button>
        </Modal>
      </Form>
    )
  }
}

ContactForm.propTypes = {
  data: PropTypes.object,
}

export default ContactForm
